import React from "react"
import { Link } from "gatsby"

const Card = ({ title, content, begin, end, link }) => (

    <li className="card">
        <Link to={link}>
        <div>
            <div className="card__line__horizontal"></div>
            <div className="card__ball"></div>
            <div className="card__title">{title}</div>
            <br></br>
            <div className="card__content">{content}</div>
            <div className="card__begin">{begin}</div>
            <div className="card__end">{end}</div>

            <a className="button button-outline" href="#">More</a>
        </div>
        </Link>
    </li>
    )


const Cards = ({children}) => (
    <div className="cards_container">
    <div className="cards">
      <ul className="card_list">
        {children}        
      </ul>
    </div>
  </div>
  )



const ProjectCards = ({projects}) => (
    <Cards>
    {projects.map((data, index) => {
      return <Card title={data.title} content={data.summary} begin={data.begin} end={data.end} link={"/"+data.slug}></Card>
    })} 
  </Cards>
)

export default ProjectCards
